import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Optional,
  signal,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { GthLoginFormModule } from '@gth-legacy';
import { LoginContract } from '@index/interfaces/login';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import { APP_ROUTES } from '@shared/helpers';

import { AuthLayoutComponent } from '../../layout/auth-layout.component';
import { LoginService } from './services/login.service';

const leftImage = {
  url: 'assets/soccer3.webp',
  alt: 'Two people playing soccer',
};

@Component({
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  imports: [GthLoginFormModule, RouterModule, CommonModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  private safeWindow = inject(SrvSafeWindowService);
  private router = inject(Router);
  private loginService = inject(LoginService);

  public routes = APP_ROUTES;
  public rememberedEmail = this.loginService.rememberedEmail; // Not currently implemented
  public loading = this.loginService.combinedLoading;
  public inDialog = signal(this.router.url.includes('auth'));

  constructor(@Optional() layout: AuthLayoutComponent) {
    if (!this.inDialog()) {
      layout.image.set(leftImage);
    }
  }

  public signUpRoute = computed(() =>
    this.inDialog() ? `${this.routes.Auth}/sign-up` : this.routes.SignUp,
  );

  public forgotPasswordRoute = computed(() =>
    this.inDialog() ? `${this.routes.Auth}/forgot-password` : this.routes.ForgotPassword,
  );

  get currentUrl() {
    return this.safeWindow.location?.href;
  }

  onLogin(contract: LoginContract) {
    this.loginService.onLogin(contract);
  }
}
