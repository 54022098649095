<div class="login-form" [class.inDialog]="inDialog()">
  <a class="logo" [routerLink]="routes.Root">
    <img src="assets/logo-g.webp" class="logo" />
  </a>
  @defer {
    <gth-login-form appearance="outline"
                    [signupRoute]="signUpRoute()"
                    [forgotPasswordRoute]="forgotPasswordRoute()"
                    (login)="onLogin($event)"
                    [email]="rememberedEmail"
                    [loading]="loading()" />
  }

  <input class="cpy-input" #urlInput [value]="currentUrl" readonly />
</div>
